import React from 'react';
import { Link } from "react-router-dom";
import dateToISOString from '../lib/dateToISOString';

class DayView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squareColor: "white"
    };

    this.onSelectScore = this.onSelectScore.bind(this)
  }

  onSelectScore(score) {
    this.props.setScore(dateToISOString(this.props.date), score);
  }

  onDeleteScore() {
    this.props.setScore(dateToISOString(this.props.date), null);
  }

  render() {
    return <div>
      <div className="day-view">
        <Link to="/">Back to calendar</Link>
        <h1>{ dateToISOString(this.props.date) }</h1>
        <div className="day-square" style={{ backgroundColor: this.props.colors[this.props.currentScore] || 'white' }}>
          <div className="square-text">
            { this.props.currentScore }
          </div>
        </div>
        <button className="score-button" id="score-button-1" onClick={() => this.onSelectScore(1)}>1</button>
        <button className="score-button" id="score-button-2" onClick={() => this.onSelectScore(2)}>2</button>
        <button className="score-button" id="score-button-3" onClick={() => this.onSelectScore(3)}>3</button>
        <button className="score-button" id="score-button-4" onClick={() => this.onSelectScore(4)}>4</button>
        <button className="score-button" id="score-button-5" onClick={() => this.onSelectScore(5)}>5</button>
        <button className="score-button" id="score-button-5" onClick={() => this.onDeleteScore()}>Clear</button>
      </div>
    </div> 
  }
}

export default DayView;
