import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import http from '../lib/http'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      errorMessage: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()

    const { email, password } = this.state

    http.post(`${process.env.REACT_APP_API_HOST}/login/`, { email, password })
      .then(response => {
        if (response.ok) {
          this.props.setUser({ email: this.state.email })
        } else {
          this.setState({ errorMessage: 'Wrong e-mail/password combination' })
        }
      }).catch(error => {
        this.setState({ errorMessage: 'Failed to log in' })
        console.error(error)
      })
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value })
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value })
  }

  render() {
    if (this.props.user.email) {
      return <Redirect to='/' />
    }

    return (
      <div className="auth-container">
        <h1>Login</h1>
        <form className="auth-form" action="" onSubmit={this.onSubmit}>
          <p className="error">{ this.state.errorMessage }</p>
          <div className="label-container">
            <label htmlFor="email-input">e-mail: </label>
          </div>
          <div>
            <input
              id="email-input"
              type="email"
              onChange={this.onChangeEmail}
              value={this.state.email} />
          </div>

          <div className="label-container">
            <label htmlFor="password-input">password: </label>
          </div>
          <div>
            <input
              id="password-input"
              type="password"
              onChange={this.onChangePassword}
              value={this.state.password} />
          </div>

          <button type="submit">Log in</button>

          <p className="link-to-other-auth-text">
            New to this? <Link to='/register' className='underscored-link link-to-other-auth'>Register</Link>
          </p>
        </form>
      </div>
    )
  }
}

export default Login
