const http = {
  post: (url, body) => {
    return fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(body),
      credentials: 'include'
    })
  },

  get: (url) => {
    return fetch(url, { credentials: 'include' })
  }
}

export default http
