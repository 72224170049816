import dateToISOString from './dateToISOString';

function isISODateLegit(shortISOString) {
  try {
    return dateToISOString(new Date(shortISOString)) === shortISOString
  } catch (e) {
    return false;
  }
}

export default isISODateLegit
