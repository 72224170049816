import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';
import DayView from './components/DayView';
import ColumnarCalendar from './components/ColumnarCalendar';
import Login from './components/Login';
import Registration from './components/Registration';
import Logout from './components/Logout';
import http from './lib/http'

const colors = {
  1: "#f2c9ec",
  2: "#ed97e0",
  3: "#e26cd1",
  4: "#db4ac5",
  5: "#ff00d8"
};


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendar: {},
      user: { email: '' }
    }

    this.setScore = this.setScore.bind(this)
    this.setUser = this.setUser.bind(this)
    this.clearUser = this.clearUser.bind(this)
  }

  componentDidMount() {
    let userJson = sessionStorage.getItem('user')
    if (userJson) {
      try {
        this.setState({ user: JSON.parse(userJson) })
      } catch (e) {
        console.error('Failed reading from session storage')
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user.email !== this.state.user.email) {
      http.get(`${process.env.REACT_APP_API_HOST}/scores/`)
        .then(response => {
          return response.json()
        }).then(calendar => {
          this.setState({ calendar })
        }).catch(error => console.error(error))
    }
  }

  setScore(day, score) {
    http.post(`${process.env.REACT_APP_API_HOST}/day/${day}`, { score })
      .then(response => {
        if (response.ok) {
          this.setState(
            { calendar: { ...this.state.calendar, [day]: { score } } }
          )
        }
      }).catch(error => {
        console.error(error)
      })
  }

  setUser(user) {
    sessionStorage.setItem('user', JSON.stringify(user))
    this.setState({ user })
  }

  clearUser() {
    sessionStorage.clear()
    this.setState({ user: { email: '' } })
  }

  render() {
    const { calendar } = this.state;

    const dayView = props => {
      const { date } = props.match.params;

      return (
        <DayView
          date={new Date(date)}
          setScore={this.setScore}
          currentScore={calendar[date] ? calendar[date].score : null}
          colors={colors}
        />
      )
    }

    return (
      <div className="App">
        <header className="App-header">
          <Router>
            { this.state.user.email && <p className="user-header">Hello, { this.state.user.email }. <Link to='/logout'>Log out</Link></p> }
            <Route path="/" exact component={props => <ColumnarCalendar year={(new Date()).getYear() + 1900} calendar={calendar} colors={colors} user={this.state.user} />} />
            <Route path="/day/:date" component={dayView} />
            <Route path="/login" component={props => <Login setUser={this.setUser} user={this.state.user}  />} />
            <Route path="/register" component={props => <Registration user={this.state.user} />} />
            <Route path="/logout" component={props => <Logout clearUser={this.clearUser} />} />
          </Router>
        </header>
      </div>
    );
  }
}

export default App;
