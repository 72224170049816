import React from 'react';
import { Redirect, Link } from "react-router-dom";
import dateToISOString from '../lib/dateToISOString';
import isISODateLegit from '../lib/isISODateLegit';

function range(startIndex, endIndex) {
  const ary = [];
  for (let x = startIndex; x <= endIndex; x++) {
    ary.push(x);
  }
  return ary;
}

/**
 * Return ISO date string like 2019-01-01.
 * Uses current year.
 * Returns null if date is wrong (e.g., 31st of February).
 */
function makeISODateString(year, monthIndex, dayIndex) {
  const twoDigitDayIndex = dayIndex >= 10 ? dayIndex : '0' + dayIndex;
  const twoDigitMonthIndex = monthIndex >= 10 ? monthIndex : '0' + monthIndex;
  if (!isISODateLegit(`${year}-${twoDigitMonthIndex}-${twoDigitDayIndex}`)) {
    return null;
  }

  const date = new Date(Date.UTC(year, monthIndex - 1, dayIndex))
  return dateToISOString(date);
}

class ColumnarCalendar extends React.Component {
  render() {
    if (!this.props.user.email) {
      return <Redirect to='/login' />
    }

    const { calendar, colors } = this.props;
    const today = new Date()

    return (
      <div className="columnar-calendar">
        <Link to={`/day/${dateToISOString(new Date())}`}>
          <button className="today-button">Set score for today</button>
        </Link>
        <table>
          <thead>
            <tr>
              <th>J</th>
              <th>F</th>
              <th>M</th>
              <th>A</th>
              <th>M</th>
              <th>J</th>
              <th>J</th>
              <th>A</th>
              <th>S</th>
              <th>O</th>
              <th>N</th>
              <th>D</th>
            </tr>
          </thead>
          <tbody>
            {
              range(1, 31).map(dayIndex => {
                return (
                  <tr key={dayIndex}>
                    {
                      range(1, 12).map(monthIndex => {
                        const isoDate = makeISODateString(this.props.year, monthIndex, dayIndex);

                        // Do not show non-existing dates
                        if (isoDate === null) {
                          return (
                            <td key={monthIndex}></td>
                          )
                        }

                        return (
                          <td
                            key={monthIndex}
                            style={{
                              backgroundColor: calendar[isoDate]
                                ? colors[calendar[isoDate].score]
                                : '#282c34',
                              border: isoDate === dateToISOString(today)
                                ? '1px solid white'
                                : undefined
                            }}
                          >
                            <Link to={"/day/" + isoDate}>
                              {dayIndex}
                            </Link>
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default ColumnarCalendar;
