import React from 'react'
import { Redirect } from 'react-router-dom'

class Logout extends React.Component {
  constructor(props) {
    super(props)
    props.clearUser()
  }

  render() {
    return <Redirect to='/login' />
  }
}

export default Logout
